// https://en.wikipedia.org/wiki/Code_page_437
// https://en.wikipedia.org/wiki/Block_Elements
// https://en.wikipedia.org/wiki/Box-drawing_character

export enum Chars {
  SMILEY = 1,
  INVERSE_SMILEY = 2,
  HEART = 3,
  DIAMOND = 4,
  CLUB = 5,
  SPADE = 6,
  BULLET = 7,
  INVERSE_BULLET = 8,

  LIGHT_SHADE = 176,
  MEDIUM_SHADE = 177,
  DARK_SHADE = 178,

  BOX_SINGLE_VERTICAL = 179,
  BOX_SINGLE_VERTICAL_AND_SINGLE_LEFT = 180,
  BOX_DOUBLE_VERTICAL_AND_DOUBLE_LEFT = 185,
  BOX_DOUBLE_VERTICAL = 186,
  BOX_DOUBLE_DOWN_AND_DOUBLE_LEFT = 187,
  BOX_DOUBLE_UP_AND_DOUBLE_LEFT = 188,
  BOX_SINGLE_DOWN_AND_SINGLE_LEFT = 191,
  BOX_SINGLE_UP_AND_SINGLE_RIGHT = 192,
  BOX_SINGLE_HORIZONTAL_AND_SINGLE_UP = 193,
  BOX_SINGLE_HORIZONTAL_AND_SINGLE_DOWN = 194,
  BOX_SINGLE_VERTICAL_AND_SINGLE_RIGHT = 195,
  BOX_SINGLE_HORIZONTAL = 196,
  BOX_SINGLE_VERTICAL_AND_SINGLE_HORIZONTAL = 197,
  BOX_DOUBLE_UP_AND_DOUBLE_RIGHT = 200,
  BOX_DOUBLE_DOWN_AND_DOUBLE_RIGHT = 201,
  BOX_DOUBLE_HORIZONTAL_AND_DOUBLE_UP = 202,
  BOX_DOUBLE_HORIZONTAL_AND_DOUBLE_DOWN = 203,
  BOX_DOUBLE_VERTICAL_AND_DOUBLE_RIGHT = 204,
  BOX_DOUBLE_HORIZONTAL = 205,
  BOX_DOUBLE_VERTICAL_AND_DOUBLE_HORIZONTAL = 206,
  BOX_SINGLE_UP_AND_SINGLE_LEFT = 13 * 16 + 9,
  BOX_SINGLE_DOWN_AND_SINGLE_RIGHT = 13 * 16 + 10,

  BLOCK_FULL = 219,
  BLOCK_BOTTOM_HALF = 13 * 16 + 12,
  BLOCK_LEFT_HALF = 13 * 16 + 13,
  BLOCK_RIGHT_HALF = 13 * 16 + 14,
  BLOCK_TOP_HALF = 13 * 16 + 15,
}
