import { fromRgb } from '../color';

/**
 * Orignal CGA palette.
 * See: https://en.wikipedia.org/wiki/Color_Graphics_Adapter
 * See: https://lospec.com/palette-list/color-graphics-adapter
 */
export const Colors = {
  BLACK: fromRgb(0, 0, 0),
  WHITE: fromRgb(0xff, 0xff, 0xff),
  LIGHT_GRAY: fromRgb(0xaa, 0xaa, 0xaa),
  DARK_GRAY: fromRgb(0x55, 0x55, 0x55),
  YELLOW: fromRgb(0xff, 0xff, 0x55),
  BROWN: fromRgb(0xaa, 0x55, 0x00),
  LIGHT_RED: fromRgb(0xff, 0x55, 0x55),
  DARK_RED: fromRgb(0xaa, 0x00, 0x00),
  LIGHT_GREEN: fromRgb(0x55, 0xff, 0x55),
  DARK_GREEN: fromRgb(0x00, 0xaa, 0x00),
  LIGHT_CYAN: fromRgb(0x55, 0xff, 0xff),
  DARK_CYAN: fromRgb(0x00, 0xaa, 0xaa),
  LIGHT_BLUE: fromRgb(0x55, 0x55, 0xff),
  DARK_BLUE: fromRgb(0x00, 0x00, 0xaa),
  LIGHT_MAGENTA: fromRgb(0xff, 0x55, 0xff),
  DARK_MAGENTA: fromRgb(0xaa, 0x00, 0xaa),
  ORANGE: fromRgb(0xff, 0x88, 0x00),
};
